<template>
    <div class="mainCon">
        <v-card>
            <div class="primary formLabel white--text">管理者画面</div>
            <v-form
                class="pa-6"
                v-model="formData.valid"
            >
                <v-text-field
                    label="Eメール"
                    outlined
                    dense
                    :rules="[
                        v => validateEmail(v) || '有効なメール形式ではありません' 
                    ]"
                    v-model="formData.email"
                />
                <v-text-field
                    label="パスワード"
                    outlined
                    dense
                    type="password"
                    :rules="[
                        v => (v || '').length >= 8 || '最小８文字入れてください' 
                    ]"
                    v-model="formData.password"
                />

                <div>
                    <v-btn class="my-1 success font-weight-bold headline" width="100%" :disabled="!formData.valid" @click="login()">ログイン</v-btn>
                    <v-btn class="my-1 primary font-weight-bold headline" width="100%" @click="$router.push('/')">メイン画面</v-btn>
                </div>
            </v-form>
        </v-card>
    </div>
</template>

<script>

export default {
    data:()=>({
        formData:{
            valid:false,
            email:"",
            password:"",
        }
    }),
    mounted(){
    },
    methods:{
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        async login(){
            try{
                this.$store.commit('setLoadingScreen',{display:true})
                let response = await this.$store.dispatch('login', this.formData)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"success",
                    icon:"mdi-alert-circle",
                    text: "ようこそ　"+response.data.name+"　様！" 
                })
                await this.$store.commit('setUserProfile', response.data)
                this.$store.commit('setLoadingScreen',{display:false})
                this.$router.replace('/dash')
            }catch(err){
                if(!this.$store.state.isProduction)
                    console.log(err)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"red",
                    icon:"mdi-alert-circle",
                    text: err.response? err.response.data  :"エラーが発生しました。" 
                })
                this.$store.commit('setLoadingScreen',{display:false})
            }
        },
    }
}
</script>

<style scoped>
.mainCon{
    display:grid;
    place-items: center;
    width:100vw;
    height:98vh;
}
.formLabel{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
}
</style>